/* 
 * @Author: 曹俊杰 
 * @Date: 2023-01-08 21:42:06
 * @Module: 卡券充值
 */
 <template>
  <div class="gold border">
    <div class="explain">
      <h3>充值说明</h3>
      <p>1、请使用支付宝进行充值；<br />
        2、账户充值后，即可使用查小号功能， 费用情况请参照<el-link type="primary" @click="beanRulesShow">“ 功能服务资费标准” </el-link>；<br />
        3、账户余额不可转让、不提现、不找零。</p>
    </div>
    <div class="title" style="margin-top:10px;">
      套餐购买
    </div>
    <div class="package">
      <div class="item pointer" :class="{active:item.packageId == choosePackage.packageId}" v-for="item in list" :key="item.packageId" @click="()=>packageClick(item)">
        <div class="item-top">
          <div class="zhe">{{(item.price/item.coins)*10}}折</div>
          <div class="jg">
            <span style="font-size:20px">¥</span>
            <span>{{item.price}}</span>
          </div>
        </div>
        <div class="item-bottom">
          <div class="num">
            <p>
              <span>数量：</span>
              <span style="color:#5E3427">{{item.coins}}卡券</span>
            </p>
            <img src="@/static/image/icon_dianquan.png" alt="" srcset="">
          </div>
          <p class="price">原价：¥{{item.coins}}</p>
        </div>
      </div>
    </div>
    <div class="title" style="margin-top:10px;">
      自定义充值
    </div>
    <div class="cell">
      <div class="label">
        充值数量：
      </div>
      <div class="value">
        <el-input style="width:220px" @input="input" :value="form.coins" size="medium" placeholder="请输入需要数量（1元=1卡券）"></el-input>
      </div>
    </div>
    <div class="solid"></div>
    <div class="cell">
      <div class="label">
        充值帐户：
      </div>
      <div class="value">
        {{company}}
      </div>
    </div>
    <div class="cell">
      <div class="label">
        充值方式：
      </div>
      <div class="value">
        <i class="el-icon-ali-zhifubao" style="color:#0062FF"></i>
        支付宝
      </div>
    </div>
    <div class="cell">
      <div class="label" style="color: #262626;">
        应付金额：
      </div>
      <div class="value" style="color: #FF0000;font-size: 22px;">
        ¥ {{price}}
      </div>
    </div>
    <div class="btn pointer" @click="postRecharge">
      充值
    </div>
    <beanRules ref="beanRules" />
    <div v-html="h" />
  </div>
</template>
 <script>
import mixin from "@/views/VoucherCenter/mixin.js";
import beanRules from "@/views/ExpenseCenter/components/beanRules.vue";
export default {
  components: {
    beanRules
  },
  mixins: [mixin],
  data () {
    return {

    };
  },
  methods: {
    async postRechargePackage () {
      const { data, status } = await this.$api.postRechargePackage({ coinType: 'BEAN' })
      if (status === 200) {
        this.list = data;
      }
    },
    //  充值
    async postRecharge () {
      try {
        let parm = {
          coinType: 'BEAN',
          returnUrl: window.location.origin + '/expenseCenter/recharge'
        };
        if (this.choosePackage.packageId) {
          parm.packageId = this.choosePackage.packageId
        } else {
          parm.coins = this.form.coins;
          if (parm.coins < 100) {
            this.$message({
              type: 'error',
              message: '卡券最低充值100个'
            })
            throw '卡券最低充值100个'
          }
        }
        const { status, info } = await this.$api.postRecharge(parm)
        if (status === 200) {
          this.h = info
          this.$nextTick(() => {
            document.forms[0].submit()
          })
        } else {
          this.$message({
            type: 'error',
            message: info
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    beanRulesShow () {
      this.$refs.beanRules.show()
    }
  },
};
 </script>
 <style lang='scss' scoped>
.gold {
  padding: 16px 20px;
  .explain {
    padding: 14px 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #262626;
    line-height: 26px;
    height: 148px;
    background: #f5f9ff;
  }
  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 50px;
    color: #262626;
  }
  .package {
    display: flex;
    flex-wrap: nowrap;
    .item {
      height: 232px;
      border: 1px solid #eeeeee;
      border-radius: 4px;
      width: calc((100% - (17px * 4)) / 5);
      margin-right: 17px;
      margin-bottom: 12px;
      &:nth-child(5n) {
        margin-right: 0px;
      }
      &:nth-last-child(-n + 5) {
        margin-bottom: 0px;
      }
      &-top {
        height: 142px;
        background: linear-gradient(113.37deg, #f7f9ff 0%, #ebeef8 100%);
        .zhe {
          width: 78px;
          height: 32px;
          left: 71px;
          background: linear-gradient(99.55deg, #c2d0eb 0%, #acbbd6 100%);
          margin: 0 auto;
          font-family: "Source Han Sans CN";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 32px;
          color: #ffffff;
          text-align: center;
        }
        .jg {
          font-family: "Source Han Sans CN";
          font-style: normal;
          font-weight: 400;
          font-size: 30px;
          line-height: 30px;
          color: #151e39;
          text-align: center;
          margin-top: 18px;
        }
      }
      &-bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .num {
          display: flex;
          align-items: center;
          margin-top: 20px;
          font-family: "Source Han Sans CN";
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #666666;
          img {
            margin-left: 4px;
          }
        }
        .price {
          margin-top: 8px;
          font-family: "Source Han Sans CN";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          text-decoration-line: line-through;
          color: #666666;
        }
      }
    }
    .active {
      .item-top {
        background-image: url(~@/static/image/cashier_bg_1.png);
        background-size: 100% auto;
        .zhe {
          background: linear-gradient(99.55deg, #ffefc6 0%, #f0d389 100%);
          // background: linear-gradient(99.55deg, #d3b873 0%, #bf9752 100%);
        }
        .jg {
          color: #fff;
        }
      }
    }
  }
  .cell {
    height: 50px;
    display: flex;
    align-items: center;
    .label {
      width: 70px;
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #999999;
    }
    .value {
      margin-left: 10px;
    }
  }
  .solid {
    width: 100%;
    height: 1px;
    background: #e3e3e3;
    margin-top: 11px;
  }
  .btn {
    width: 110px;
    background: #0062ff;
    border-radius: 4px;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    color: #ffffff;
    margin-top: 24px;
  }
}
.border {
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  background-color: #fff;
}
</style>