import { mapState } from "vuex";
export default {
	data () {
		return {
			list: [],
			choosePackage: {
				packageId: null,
			},
			form: {
				coins: null
			},
			price: 0,
			h: '',
		};
	},
	computed: {
		...mapState({
			balance: state => state.user.balance,
			company: state => state.user.user.company,
			beans: state => state.user.beans,
		})
	},
	mounted () {
		this.postRechargePackage()
	},
	methods: {
		// 套餐点击
		packageClick (item) {
			this.choosePackage = item;
			this.form.coins = null;
			this.price = item.price
		},
		input (text) {
			if (this.$utils.test.number(text) || this.$utils.test.empty(text)) {
				this.form.coins = text;
				this.clear();
				this.price = text || 0
			}
		},
		clear () {
			this.choosePackage = {
				packageId: null,
			}
		},
	}
}